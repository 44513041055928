<template>
  <div>
    <!-- 顶部搜索栏 -->
    <el-row>
      <el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container flx ali-c">
          <el-row>
            <el-input
              placeholder="订单编号"
              class="search-input"
              clearable
              v-model="searchObj.orderNumber"
            ></el-input>
            <el-input
              placeholder="手机号"
              clearable
              class="search-input ml-15"
              v-model="searchObj.phone"
            ></el-input>
            <el-select
                    v-model="searchObj.refundStatus"
                    clearable
                    placeholder="审核状态"
                    class="ml-15">
              <el-option
                      label="待初审"
                      value="0">
              </el-option>
              <el-option
                      label="待复审"
                      value="1">
              </el-option>
            </el-select>
            <el-button
              type="primary"
              icon="el-icon-search"
              class="ml-15"
              @click="initData"
              >查询</el-button
            >
            <el-button icon="el-icon-refresh" class="ml-15" @click="reset"
              >重置</el-button
            >
          </el-row>
        </div>
      </el-col>
    </el-row>

    <el-row class="mt-24">
      <el-col el-col :xs="24" :sm="24" :lg="24">
        <div class="pad-container">
          <!-- 退款状态 -->
          <el-tabs v-model="searchObj.auditStatus" @tab-click="changeTab">
            <el-tab-pane label="待审核" name="0"></el-tab-pane>
            <el-tab-pane label="已审核" name="1"></el-tab-pane>
          </el-tabs>

          <!-- 表格 -->
          <el-table class="mt-24 member-table" :data="tableData">
            <el-table-column type="expand" fixed>
              <template slot-scope="scope">
                <el-form label-position="left" inline class="demo-table-expand">
                  <template v-for="goods in scope.row.goodsList">
                    <el-form-item label="商品名称">
                      <span>{{ goods.goodsName }}</span>
                    </el-form-item>
                    <el-form-item label="价格">
                      <span>{{ goods.goodsPrice }}</span>
                    </el-form-item>
                    <el-form-item label="数量">
                      <span>{{ goods.goodsNum }}</span>
                    </el-form-item>
                  </template>
                </el-form>
              </template>
            </el-table-column>
            <el-table-column label="订单编号" prop="orderNumber" width="200" fixed></el-table-column>
            <el-table-column label="支付号" prop="outTradeNo" width="200" fixed></el-table-column>
            <el-table-column label="客户昵称" prop="nickname"></el-table-column>
            <el-table-column label="手机号码" prop="phone"></el-table-column>
            <el-table-column
              label="所属门店"
              prop="storeName"
            ></el-table-column>
            <el-table-column label="订单类型">
              <template slot-scope="scope">
                <span>{{ getOrderType(scope.row.orderType) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="订单金额">
              <template slot-scope="scope">
                <span>￥{{ scope.row.actualPay || 0 }}</span>
              </template>
            </el-table-column>
            <el-table-column label="惠购券">
              <template slot-scope="scope">
                <span>￥{{scope.row.hgoCouponReduce || 0}}</span>
              </template>
            </el-table-column>
            <el-table-column label="消耗积分">
              <template slot-scope="scope">
                <span>{{scope.row.useIntegral || 0}}</span>
              </template>
            </el-table-column>
            <!-- <el-table-column label="付款方式" prop="pay_way"></el-table-column> -->
            <el-table-column
            label="退款前状态"
            width="100px">
              <template slot-scope="scope">
                <span>{{ getBeforeRefund(scope.row.beforeRefundStatus) }}</span>
              </template>
            </el-table-column>
            <el-table-column label="订单状态">
              <template slot-scope="scope">
                <span :class="scope.row.refundStatus == 0 && scope.row.beforeRefundStatus == 3 ? 'red' : ''">{{ getString(scope.row.refundStatus, scope.row.beforeRefundStatus) }}</span>
              </template>
            </el-table-column>
            <el-table-column
              label="下单时间"
              prop="createTime"
            ></el-table-column>
            <el-table-column label="已审核人">
              <template slot-scope="scope">
                <span>
                  {{ scope.row.operationPerson || "" }}&nbsp;&nbsp;{{
                    scope.row.financePerson || ""
                  }}&nbsp;&nbsp;{{
                    scope.row.operationConfirmPerson || ""
                  }}
                </span>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="300">
              <template slot-scope="scope">
                <!-- refundStatus 退款审核状态，0待审核 1运营通过 2运营驳回 3财务通过 4财务驳回 5运营确认通过 6运营确认不通过 -->
                <div class="flx-row ali-c">
                  <!-- <div>{{identityType}}--{{scope.row.refundStatus}}</div> -->
                  <!-- scope.row.refundStatus == 3      财务审核状态不再支持继续审核-->
                  <el-button
                    v-if="
                      (identityType == 0 || identityType == 1) &&
                        (scope.row.refundStatus == 0  ||
                          scope.row.refundStatus == 4)
                    "
                    @click="check(scope.row, 1)"
                    type="text"
                    >审核</el-button>
                  <el-button
                    v-else-if="(identityType == 0 || identityType == 2) && scope.row.refundStatus == 1"
                    @click="check(scope.row, 1)"
                    type="text"
                    >审核</el-button
                  >
                  <el-button v-else type="text" @click="check(scope.row, 2)"
                    >详情</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>

          <div class="flx-row ali-c js-c mt-24">
            <Pagination
              :total="total"
              :pageNum="pageNum"
              :pageSize="pageSize"
              :pageSizes="pageSizes"
              @changePage="initData"
              @syncPageData="syncPageData"
            />
          </div>
        </div>
      </el-col>
    </el-row>
    <!-- 审核弹窗 start -->
    <el-dialog :visible.sync="checkDialog" :title="title" @close="cancel">
      <el-steps :active="stepActive" align-center finish-status="success">
        <el-step
          :title="item.title"
          v-for="item in checkInfo"
          :key="item.title"
        ></el-step>
      </el-steps>
      <el-row>
        <div class="validate-options" v-for="(item, title) in checkInfo">
          <div>
            <label>商品退回状态：</label>
            <span>{{item.goodsReturnStatus}}</span>
          </div>
          <div>
            <label>审核意见：</label>
            <span>{{item.refundOptions}}</span>
          </div>
          <div>
            <label>退款理由：</label>
            <span>{{item.reason}}</span>
          </div>
        </div>
      </el-row>
      <div class="mt-24 c-6 flx-row ali-c flx-wr">
        <div class="form-input-100 tl-r">退款前状态：</div>
        <div class="wid-form">{{ getBeforeRefund(orderDetail.beforeRefundStatus) }}</div>
      </div>
      <div class="mt-24 c-6 flx-row ali-c flx-wr">
        <div class="form-input-100 tl-r">订单状态：</div>
        <div class="wid-form" v-if="orderDetail.status == 4">退款中</div>
        <div class="wid-form" v-if="orderDetail.status == 5">已退款</div>
        <div class="wid-form" v-if="orderDetail.status == -1">拒绝退款</div>
      </div>
      <div class="mt-24 c-6 flx-row ali-c flx-wr">
        <div class="form-input-100 tl-r">退款单号：</div>
        <div class="wid-form">{{ orderDetail.refundNumber }}</div>
      </div>
      <div class="mt-24 c-6 flx-row ali-c flx-wr">
        <div class="form-input-100 tl-r">订单编号：</div>
        <div class="wid-form">{{ orderDetail.orderNumber }}</div>
      </div>
      <div class="mt-24 c-6 flx-row ali-c flx-wr">
        <div class="form-input-100 tl-r">客户昵称：</div>
        <div class="wid-form">{{ orderDetail.nickname }}</div>
      </div>
      <div class="mt-24 c-6 flx-row ali-c flx-wr">
        <div class="form-input-100 tl-r">订单信息：</div>
      </div>
      <div class="mt-24 c-6 ml-20">
        <el-table :data="captureObj.list" border class="good-wrapper">
          <el-table-column label="商品名称" prop="goodsName"></el-table-column>
          <el-table-column label="商品图片">
            <template slot-scope="scope">
              <el-avatar
                :src="picsPath(scope.row.goodsUrl)"
                shape="square"
                alt="加载失败"
              ></el-avatar>
            </template>
          </el-table-column>
          <!-- 实价 -->
          <el-table-column label="单价" prop="goodsPrice">
            <template slot-scope="scope">
              <span>￥{{ scope.row.goodsPrice }}</span>
            </template>
          </el-table-column>
          <el-table-column label="数量" prop="goodsNum"></el-table-column>
          <el-table-column label="总计">
            <template slot-scope="scope">
              <span>￥{{ scope.row.goodsPrice * scope.row.goodsNum }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="mt-24 c-6 flx-row ali-c flx-wr">
        <div class="form-input-100 tl-r">总计：</div>
        <div class="wid-form">{{ captureObj.totalPay }}</div>
      </div>
      <div class="mt-24 c-6 flx-row ali-c flx-wr">
        <div class="form-input-100 tl-r">惠购券：</div>
        <div class="wid-form">{{ captureObj.hgoCouponReduce }}</div>
      </div>
      <div class="mt-24 c-6 flx-row ali-c flx-wr">
        <div class="form-input-100 tl-r">积分：</div>
        <div class="wid-form">{{ captureObj.useIntegral }}</div>
      </div>
      <div class="mt-24 c-6 flx-row ali-c flx-wr">
        <div class="form-input-100 tl-r">运费：</div>
        <div class="wid-form">{{ captureObj.freightPrice }}</div>
      </div>
      <div class="mt-24 c-6 flx-row ali-c flx-wr">
        <div class="form-input-100 tl-r">优惠：</div>
        <div class="wid-form">
          {{ captureObj.discount + captureObj.couponAmount }}
        </div>
      </div>

      <div class="mt-24 c-6 flx-row ali-c flx-wr">
        <div class="form-input-100 tl-r">退款金额：</div>
        <div class="wid-form">
          {{ captureObj.financeRefund || captureObj.dueRefund || 0 }}
        </div>
      </div>
      <div class="mt-24 c-6 flx-row ali-c flx-wr">
        <div class="form-input-100 tl-r">退款原因：</div>
        <div class="tl-r">{{ captureObj.refundReason }}</div>
      </div>
      <div class="mt-24 c-6 flx-row ali-c flx-wr" v-if="captureObj.refundImgs">
        <div class="tl-l ml-30">
          <div
            class="demo-image__preview"
            v-if="captureObj.refundImgs.indexOf(',') > -1"
          >
            <el-image
              style="width: 160px; height: 160px;margin:0 20px 20px 0;"
              :src="picsPath(item)"
              :preview-src-list="srcList"
              v-for="(item, index) in captureObj.refundImgs.split(',')"
              :key="index"
            >
            </el-image>
          </div>
          <div class="demo-image__preview" v-else>
            <el-image
              style="width: 160px; height: 160px;margin:0 20px 20px 0;"
              :src="picsPath(captureObj.refundImgs)"
              :preview-src-list="srcList"
            >
            </el-image>
          </div>
        </div>
      </div>
      <el-divider></el-divider>

      <template v-if="isVerify == 1">
        <el-form
          :model="form"
          :rules="rules"
          ref="form"
          label-position="right"
          label-width="100px"
        >
          <el-form-item prop="price" label="审核金额">
            <el-input
              :disabled="form.formDisabled"
              v-model="form.price"
              placeholder="请输入审核金额"
            ></el-input>
          </el-form-item>
          <el-form-item prop="goodsReturnStatus" label="商品状态">
            <el-input
                    v-model="form.goodsReturnStatus"
                    placeholder="请输入商品退回状态，是否已出货、已退回门店、已确认不影响二次销售，请详细描述。"
                    type="textarea"
                    resize="none"
            ></el-input>
          </el-form-item>
          <el-form-item prop="refundOptions" label="审核意见">
            <el-input
                    v-model="form.refundOptions"
                    placeholder="请输入审核意见"
                    type="textarea"
                    resize="none"
            ></el-input>
          </el-form-item>
          <el-form-item prop="reason" label="审核理由">
            <el-input
              v-model="form.reason"
              placeholder="请输入审核理由"
              type="textarea"
              resize="none"
            ></el-input>
          </el-form-item>
        </el-form>
      </template>
      <div class="flx-row ali-c js-c mt-50" v-if="isVerify == 1">
        <el-button @click="confirm('form', 0)">审核拒绝</el-button>
        <el-button type="primary" @click="confirm('form', 1)"
          >审核通过</el-button
        >
      </div>
    </el-dialog>
    <!-- 审核弹窗 end -->
  </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import {
  getOrderList,
  getOrderDetail,
  refundOperation,
  refundFinance,
  refundOperationConfirm,
} from "@/api/transactions/order/orders_department";
export default {
  components: { Pagination },
  data() {
    return {
      form: { goodsReturnStatus: "", refundOptions: "", reason: "", price: 0, formDisabled: true },
      rules: {
        price: [
          //{ required: true, message: "审核金额不能为空", trigger: "change" },
          {
            // pattern: /^[\d]+(\.[\d]{2})?$/,
            pattern: /(^[1-9](\d+)?(\.\d{1,2})?$)|(^\d\.\d{1,2}$)/,
            message: "大于0且最多两位小数",
            trigger: "change",
          },
        ],
        goodsReturnStatus: [
          {
            required: true,
            trigger: "blur",
            message: "商品状态不能为空",
          },
        ],
        refundOptions: [
          {
            required: true,
            trigger: "blur",
            message: "审核意见不能为空",
          },
        ],
        reason: [
          {
            required: true,
            trigger: "blur",
            message: "理由不能为空",
          },
        ],
      },
      captureObj: {},
      //搜索栏相关数据
      searchObj: {
        orderNumber: "", //订单编号
        phone: null, //手机号
        storeId: null, //门店id
        refundStatus: null, //		退款审核状态，0待审核 1运营通过 2运营驳回 3财务通过 4财务驳回 5运营确认通过 6运营确认不通过
        refundNumber: "", //退款单号
        auditStatus: 0, //审核状态：0.待审核 1.已审核
      },
      // 订单状态与门店列表
      order_status: [
        {
          id: 0,
          title: "待退款审核",
        },
        {
          id: 1,
          title: "运营通过",
        },
        {
          id: 2,
          title: "运营驳回",
        },
        {
          id: 3,
          title: "财务通过",
        },
        {
          id: 4,
          title: "财务驳回",
        },
        {
          id: 5,
          title: "运营确认通过",
        },
        {
          id: 6,
          title: "运营确认不通过",
        },
      ],
      // 订单退款前的状态
      before_refund_status: [
        {
          id: 0,
          title: "未支付",
        },
        {
          id: 1,
          title: "已支付",
        },
        {
          id: 2,
          title: "已发货",
        },
        {
          id: 3,
          title: "已完成",
        },
        {
          id: 4,
          title: "退款中",
        },
        {
          id: 5,
          title: "已退款",
        },
        {
          id: 6,
          title: "已失效",
        },
        {
          id: -1,
          title: "拒绝退款",
        },
        {
          id: 15,
          title: "拼团中",
        },
      ],
      order_type: [
        {
          id: 1,
          title: "正常交易",
        },
        {
          id: 2,
          title: "积分活动",
        },
        {
          id: 3,
          title: "砍价活动",
        },
        {
          id: 6,
          title: "多人拼团",
        },
        {
          id: 7,
          title: "限时抢购",
        },
        {
          id: 8,
          title: "分享集卡",
        },
      ],
      store_list: [],
      // 会员表格数据内容
      tableData: [],
      //用于分页
      total: 0,
      pageNum: 1,
      pageSize: 10,
      pageSizes: [10, 20, 30, 50, 100],
      url: "/order-service/order/smallProgram?export=1",
      checkDialog: false,
      disabled: false,
      orderDetail: {},
      status: "",
      title: "审核详情",
      stepActive: -1, //接受一个Number，表明步骤的 index，从 0 开始
      checkInfo: [
        {
          title: "运营初审",
          reason: "",
        },
        {
          title: "财务确认",
          reason: "",
        }
      ],
      identityType: 1, //假定身份  运营
      isVerify: -1, //1为审核按钮  2为详情按钮
      srcList: [],
    };
  },
  mounted() {
    //type 角色类型（0 平台管理员，1 运营人员，2 财务人员  3.门店管理人员）
    try {
      //  console.log(this.$store.getters.userObject);
      if (this.$store.getters.userObject.userRoleVos)
        this.identityType = this.$store.getters.userObject.userRoleVos[0].type;
    } catch (err) {
      // console.log(err);
    }
    this.initData();
  },
  methods: {
    confirm(formName, status) {
      //status1通过  0拒绝
      // operationReason//运营审核理由（第一阶段）
      //financeReason//财务审核理由（第二阶段）
      //operationConfirmReason//运营确认审核理由（第三阶段）
      //refuseRefundReason//拒绝退款理由
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // console.log(this.form);
          this.refund(status);
        } else {
          return false;
        }
      });
    },
    cancel() {
      try {
        this.$refs.form.resetFields();
      } catch (err) {
        // console.log(err);
      }

      this.checkDialog = false;
    },
    check(row, type) {
      // 审核弹窗出现  type1审核  2查看详情
      // console.log("row", row);
      this.isVerify = type;
      this.checkDialog = true;
      this.orderDetail = row;
      this.checkInfo[0].reason = row.operationReason; //运营初审
      this.checkInfo[1].reason = row.financeReason; //财务
      //this.checkInfo[2].reason = row.operationConfirmReason; //运营终审
      if (this.identityType == 2) {
        //财务
        this.form.formDisabled = false;
      } else {
        this.form.formDisabled = true;
      }
      //console.log('inside check function')
      getOrderDetail(row.id).then((res) => {
        //console.log("详情", res.data.body);
        this.captureObj = res.data.body;
        this.checkInfo[0].goodsReturnStatus = res.data.body.operationGoodsReturnStatus;
        this.checkInfo[0].refundOptions = res.data.body.operationRefundOptions;
        this.checkInfo[1].goodsReturnStatus = res.data.body.financialGoodsReturnStatus;
        this.checkInfo[1].refundOptions = res.data.body.financialRefundOptions;
        if (res.data.body.refundImgs) {
          let newArr = res.data.body.refundImgs.split(",").map((ele) => {
            return (
              process.env.VUE_APP_BASE_API_IMG + "/file-service/file/" + ele
            );
          });
          this.srcList = newArr;
        } else {
          this.srcList = [];
        }
        // if (this.identityType == 1 && this.captureObj.refundStatus == 3) {
        //   this.form.price = this.captureObj.financeRefund;
        // } else {
        //   this.form.price = this.captureObj.dueRefund;
        // }
        this.form.price =
          this.captureObj.financeRefund || this.captureObj.dueRefund;
        if (String(res.data.body.refundStatus)) {
          //退款审核状态，	退款审核状态，0待审核 1运营通过 2运营驳回 3财务通过 4财务驳回 5运营确认通过 6运营确认不通过
          switch (res.data.body.refundStatus) {
            case 0:
            case 1:
              this.stepActive = Number(res.data.body.refundStatus);
              break;
            case 4:
              this.stepActive = 0;
              break;
            case 3:
              this.stepActive = 2;
              break;
            case 5:
              this.stepActive = 3;
              break;
            default:
              this.stepActive = -1;
          }
        }
      });
    },
    refund(status) {
      // 审核退款
      // console.log(this.orderDetail);
      //status1通过  0拒绝
      if (status == 1) {
        let params = {
          id: this.orderDetail.id,
          isPass: status,
          goodsReturnStatus: this.form.goodsReturnStatus,
          refundOptions: this.form.refundOptions
        };
        switch (this.identityType) {
          case 0: //管理员
            params.financeReason = this.form.reason;
            params.financeRefund = this.form.price;
            refundFinance(params).then((res) => {
              this.$message({
                message: "操作成功",
                type: "success",
              });
              this.checkDialog = false;
              this.initData();
            });

            break;
          case 1: //运营
            // refundStatus	退款审核状态，0待审核 1运营通过 2运营驳回 3财务通过 4财务驳回 5运营确认通过 6运营确认不通过
            if (
              this.orderDetail.refundStatus == 0 ||
              this.orderDetail.refundStatus == 4
            ) {
              //运营初审
              params.operationReason = this.form.reason;
              refundOperation(params).then((res) => {
                this.$message({
                  message: "操作成功",
                  type: "success",
                });
                this.checkDialog = false;
                this.initData();
              });
            } else if (this.orderDetail.refundStatus == 3) {
              //运营确认
              params.operationConfirmReason = this.form.reason;
              refundOperationConfirm(params).then((res) => {
                this.$message({
                  message: "操作成功",
                  type: "success",
                });
                this.checkDialog = false;
                this.initData();
              });
            }

            break;
          case 2: //财务
            if (this.orderDetail.refundStatus == 1) {
              params.financeReason = this.form.reason;
              params.financeRefund = this.form.price;
              refundFinance(params).then((res) => {
                this.$message({
                  message: "操作成功",
                  type: "success",
                });
                this.checkDialog = false;
                this.initData();
              });
            }

            break;
            
          default:
            break;
        }
      } else {
        //点击拒绝
        let params = {
          id: this.orderDetail.id,
          isPass: status,
          refuseRefundReason: this.form.reason,
          goodsReturnStatus: this.form.goodsReturnStatus,
          refundOptions: this.form.refundOptions
        };
        switch (this.identityType) {
          case 1:
            if (
              this.orderDetail.refundStatus == 0 ||
              this.orderDetail.refundStatus == 4
            ) {
              refundOperation(params).then((res) => {
                //运营初审
                this.$message({
                  message: "拒绝退款成功",
                  type: "success",
                });
                this.checkDialog = false;
                this.initData();
              });
            } else if (this.orderDetail.refundStatus == 3) {
              //运营终审
              refundOperationConfirm(params).then((res) => {
                this.$message({
                  message: "拒绝退款成功",
                  type: "success",
                });
                this.checkDialog = false;
                this.initData();
              });
            }
            break;
          case 2:
            if (this.orderDetail.refundStatus == 1) {
              //财务人员
              params.financeRefund = this.form.price;
              refundFinance(params).then((res) => {
                this.$message({
                  message: "拒绝退款成功",
                  type: "success",
                });
                this.checkDialog = false;
                this.initData();
              });
            }
            break;
          default:
            break;
        }
      }
    },

    // 切换tab
    changeTab(tab, event) {
      this.searchObj.auditStatus = tab.name;
      this.initData();
    },
    //初始化状态
    initData() {
      if (this.$store.getters.storeId) {
        this.disabled = true;   
        this.searchObj.storeId = this.$store.getters.storeId;        
      }
      // console.log("searchObj", this.searchObj);
      let data = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        ...this.searchObj,
      };
      // console.log("data", data);
      getOrderList(data).then((res) => {
        // console.log("订单数据", res.data.body);
        this.tableData = res.data.body.list;
        this.total = res.data.body.total;
      });
    },
    // 重置搜索条件
    reset() {
      this.searchObj = {
        orderNumber: "",
        phone: null,
        status: null,
        // storeId: null,
        auditStatus: 0,
      };
      this.initData();
    },
    // 获取订单状态与类型
    getString(val, beforeRefundStatus) {
      if(beforeRefundStatus == 3 && val == 0) {
        return "待退货审核"
      }

      let temp = "";

      this.order_status.forEach((e) => {
        if (e.id == val) {
          temp = e.title;
        }
      });

      return temp || "暂无";
    },
    getBeforeRefund(val) {
      let temp = "";

      this.before_refund_status.forEach((e) => {
        if (e.id == val) {
          temp = e.title;
        }
      });

      return temp || "暂无";
    },
    getOrderType(val) {
      let temp = "";

      this.order_type.forEach((e) => {
        if (e.id == val) {
          temp = e.title;
        }
      });

      return temp || "暂无";
    }
    ,
    syncPageData(data) {
      this.pageNum = data.num;
      this.pageSize = data.size;
    },
  },
};
</script>
<style lang="scss" scoped>
.txt-tip-info {
  // color: #409eff;
  cursor: pointer;
}
.capture {
  padding: 20px;
  color: #000;
}
.capture div {
  margin-bottom: 5px;
}
.captureTable {
  border-collapse: collapse;
  border-top: solid #ccc 1px;
  border-left: solid #ccc 1px;
  width: 100%;
  margin-bottom: 5px;
}
.captureTable td,
.captureTable th {
  border-right: solid #ccc 1px;
  border-bottom: solid #ccc 1px;
  text-align: center;
  height: 40px;
}
.hr {
  height: 1px;
  width: 100%;
  background: #999;
  margin-bottom: 5px;
  display: inline-block;
}
.question {
  font-size: 30px;
  color: orange;
  margin-right: 5px;
}
.title1 {
  font-size: 18px;
  color: #333;
  font-size: weight;
  margin-bottom: 5px;
}
.title2 {
  font-size: 14px;
  color: #999;
}
.width {
  margin-top: 10px;
}
.img {
  width: 160px;
  height: 160px;
  margin: 20px;
}
.good-wrapper {
  box-sizing: border-box;
  width: 100%;
}

.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 20%;
}
.red {
    color: red
}
.validate-options {
  text-align: center;
  display: inline-block;
  width: 50%;
  border-left: 1px solid #cccccc;
  border-right: 1px solid #cccccc;
}
</style>
